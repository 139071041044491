export enum ERouteName {
  AccountReviews = 'account-reviews',
  Category = 'category',
  Checkout = 'checkout',
  CheckoutCart = 'checkout-cart',
  CheckoutDelivery = 'checkout-delivery',
  CheckoutPayment = 'checkout-payment',
  CheckoutSuccess = 'checkout-success',
  Contact = 'contact',
  Designers = 'designers',
  Handmade = 'handmade',
  Homepage = 'index',
  LatestFinds = 'latestFinds',
  Product = 'product',
  ProductSimilars = 'product-similars',
  Sales = 'sales',
  Search = 'search',
  SearchSeo = 'searchSeo',
  Selections = 'selections-id-slug',
  ShopsId = 'shops-id',
}
